<template>
	<div class="page_main">
		<van-sticky>
			<van-tabs v-model="active" title-active-color="#32B16C" color="#32B16C" sticky @change="tabIndexChange">
				<van-tab title="全部"></van-tab>
				<van-tab title="待处理"></van-tab>
				<van-tab title="已处理"></van-tab>
				<van-tab title="已取消"></van-tab>
			</van-tabs>
		</van-sticky>

		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-empty description="暂无任何订单~" style="height:500px" :image="require('@/assets/image/nothing.png')" v-show="list.length == 0" />
			<van-list v-model="loading" v-show="list.length != 0" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<serve-list-item @click.native="onServeDetails(item)" v-for="item in list" :key="item.uuid" :repair="item"></serve-list-item>
			</van-list>
		</van-pull-refresh>
	</div>
</template>
<script>
import serveListItem from '@/views/serve/serve-list-item';
import { getRepairList } from '@/api/repairs.js';
import { initDictOptions } from '@/api/login.js';
export default {
	props: {},
	components: { serveListItem },
	data() {
		return {
			msg: 'Hello World!',
			active: 0,
			list: [],
			refreshing: false,
			finished: false,
			error: false,
			loading: false,
			repairPartAry: {},
			repaireTypeAry: {},
			totalPage: 0,
			queryParams: {
				order: 'desc',
				pageNo: 1,
				pageSize: 10,
				queryField: [					
					{
						field: 'applyUserId',
						queryType: '',
						type: '',
						value: JSON.parse(localStorage.getItem('userInfo')).uuid
					}
				],
				sort: 'createTime'
			}
		};
	},
	created() {				
		this.active = this.$router.currentRoute.query && this.$router.currentRoute.query.active ? this.$router.currentRoute.query.active : 0;
		var that = this;
		Promise.all([this.getLocation(), this.getType()]).then(function(values) {
			console.log(values);
			that.getRepairList();
		});
	},
	mounted() {},
	methods: {
		onLoad() {
			console.log('onLoad');
			// vant-list获取数据
			this.loading = true;
			this.queryParams.pageNo = this.queryParams.pageNo + 1;
			this.getRepairList();
		},
		onRefresh() {
			console.log('onRefresh');
			// 下拉刷新，重置数据
			this.list = [];
			this.finished = false;
			this.queryParams.pageNo = 0;
			this.onLoad();
		},
		onServeDetails(item) {
			this.$router.push({
				name: 'serve-details',
				query: {
					uuid: item.uuid
				}
			});
		},
		tabIndexChange() {
			this.list = [];
			// -1已取消、0已提交、1已派单、2已退回、4已受理，6维修完成，9已完成（已评价）
			var scheduleStatus = ['', '0,2,1,4', '6,9', '-1'];
			this.queryParams.queryField = [
				{
					field: 'scheduleStatus',
					queryType: '',
					type: '',
					value: scheduleStatus[this.active]
				},
				{
					field: 'applyUserId',
					queryType: '',
					type: '',
					value: JSON.parse(localStorage.getItem('userInfo')).uuid
				}
			];
			this.getRepairList();
		},
		getRepairList() {
			getRepairList(this.queryParams)
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						for (let i = 0; i < res.data.rows.length; i++) {
							res.data.rows[i]['repairPartName'] = this.repairPartAry[res.data.rows[i]['repairPart']];
							res.data.rows[i]['repaireTypeName'] = this.repaireTypeAry[res.data.rows[i]['repaireType']];
							this.list.push(res.data.rows[i]);
						}
						if (this.queryParams.pageNo >= res.data.totalPage) {
							this.finished = true;
						}
					} else {
						this.$toast.fail(res.message);
					}
					console.log(this.list);
				})
				.finally(() => {
					this.loading = false;
					this.refreshing = false;
				});
		},
		// 获取报修类型字典
		getType() {
			return initDictOptions('zm_repairs_type').then(res => {
				res.forEach(item => {
					this.repaireTypeAry[item.value] = item.text;
				});
			});
		},
		// 报修位置
		getLocation() {
			return initDictOptions('zm_repair_location').then(res => {
				// console.log(res)
				res.forEach(item => {
					this.repairPartAry[item.value] = item.text;
				});
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.page_main {
	background-color: #efefef;
	min-height: 100vh;
}
</style>
