<template>
  <div class="list_item_area">
    <div class="item_head pad_14 border_b fs_14">
      <div class="item_head_text">
        报修单号：{{repair.repairNo}}
      </div>
      <div :class="scheduleStatusColor[repair.scheduleStatus]"> {{scheduleStatus[repair.scheduleStatus]}}</div>
    </div>
    <div class="item_body pad_14 border_b">
      <div class="item_body_item">
        <div class="body_title">报修地点：</div>
        <div class="body_content">{{repair.repairLocation}}</div>
      </div>
      <div class="item_body_item">
        <div class="body_title">类型/位置：</div>
        <div class="body_content">{{repair.repaireTypeName}}，{{repair.repairPartName}}</div>
      </div>
      <div class="item_body_item">
        <div class="body_title">描述：</div>
        <div class="body_content">{{repair.description}}</div>
      </div>
    </div>
    <div class="item_footer fs_12">
      报修时间：{{repair.createTime}}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    repair: {
      type: Object,
      required: true
    },
  },
  components: {},
  data () {
    return {
      msg: 'Hello World!',
      scheduleStatusColor: {"-1":"item_head_cancel","0":"item_head_await","1":"item_head_await","2":"item_head_success",
	  "4":"item_head_success","6":"item_head_success","9":"item_head_success"},
      scheduleStatus: {"-1":"已取消","0":"已提交","1":"已派单","2":"已退回","4":"已受理","6":"维修完成","9":"已完成"}
    }
  },
  created () { },
  mounted () { },
  methods: {
    // 格式化当前时间
    dateFormat (fmt, date) {
      if (date) {
        date = new Date(date)
      } else {
        date = new Date()
      }
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
      }
      return fmt;
    }
  }
}
</script>
<style lang="scss" scoped>
.list_item_area {
  background: #ffffff;
  border-top: 6px solid #efefef;
  width: 100%;
}
.pad_14 {
  padding: 14px;
}
.border_b {
  border-bottom: 1px solid #ebebeb;
}
.item_head {
  display: flex;
  justify-content: space-between;
  .item_head_text {
    color: #666666;
  }
  .item_head_await {
    color: #e60012;
  }
  .item_head_success {
    color: #22ac38;
  }
  .item_head_cancel {
    color: #959595;
  }
}
.item_body {
  .item_body_item {
    display: flex;
    line-height: 1.5;
    .body_title {
      color: #626262;
    }
    .body_content {
      flex: 1;
      color: #333333;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行 （只显示一行）
    }
  }
}
.item_footer {
  line-height: 1.2;
  color: #666666;
  display: flex;
  justify-content: flex-end;
  padding: 16px 14px 11px 14px;
}
</style>